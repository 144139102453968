.sliding-banner {
    width: 100%;
    overflow: hidden;
    background-color: green;
    color: white;
    font-size: 24px;
    line-height: 50px;
    height: 50px;
}

.banner-content {
    display: inline-block;
    white-space: nowrap;
    animation: slide linear infinite;
    animation-duration: 120s;  /* Adjust timing as desired */
}

@keyframes slide {
    from { transform: translateX(0); }
    to { transform: translateX(-100%); }
}
